var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("vs-divider", { staticClass: "mb-5" }),
      _vm._m(0),
      _c("div", { staticClass: "vx-row" }, [
        _c("div", { staticClass: "vx-col w-full md:w-1/2" }, [
          _c("div", { staticClass: "vx-row" }, [
            _vm._m(1),
            _c("div", { staticClass: "vx-col" }, [
              _c("span", [
                _vm._v(" " + _vm._s(_vm.transferData.point1Name) + " "),
              ]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "vx-col w-full md:w-1/2" }, [
          _c("div", { staticClass: "vx-row" }, [
            _vm._m(2),
            _c("div", { staticClass: "vx-col" }, [
              _c("span", [
                _vm._v(
                  " " +
                    _vm._s(_vm.transferData.point1Latitude) +
                    " / " +
                    _vm._s(_vm.transferData.point1Longitude) +
                    " "
                ),
              ]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "vx-col w-full md:w-1/2" }, [
          _c("div", { staticClass: "vx-row" }, [
            _vm._m(3),
            _c("div", { staticClass: "vx-col" }, [
              _c("span", [
                _vm._v(" " + _vm._s(_vm.transferData.point2Name) + " "),
              ]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "vx-col w-full md:w-1/2" }, [
          _c("div", { staticClass: "vx-row" }, [
            _vm._m(4),
            _c("div", { staticClass: "vx-col" }, [
              _c("span", [
                _vm._v(
                  " " +
                    _vm._s(_vm.transferData.point2Latitude) +
                    " / " +
                    _vm._s(_vm.transferData.point2Longitude) +
                    " "
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "vx-row" }, [
        _c("div", { staticClass: "vx-col w-full md:w-1/2" }, [
          _c("div", { staticClass: "vx-row" }, [
            _vm._m(5),
            _c("div", { staticClass: "vx-col" }, [
              _c("span", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.getDateFormat(
                        _vm.transferData.outPickupDateTime,
                        _vm.transferData.returnPickupDateTime
                      )
                    )
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
      _vm._m(6),
      _vm._l(_vm.transferData.passengers, function (pass, passengerIndex) {
        return [
          _c("div", { key: passengerIndex + "01", staticClass: "vx-row" }, [
            _c(
              "div",
              {
                key: passengerIndex + "0",
                staticClass: "vx-col",
                class: pass.type.toLowerCase() === "child" ? "" : "w-full",
              },
              [_c("span", [_vm._v(" " + _vm._s(pass.type))])]
            ),
            pass.type.toLowerCase() === "child"
              ? _c(
                  "div",
                  { key: passengerIndex + "1", staticClass: "vx-col" },
                  [_c("span", [_vm._v("Age: " + _vm._s(pass.age))])]
                )
              : _vm._e(),
          ]),
        ]
      }),
      _c("div", { staticClass: "vx-row" }, [
        _c(
          "div",
          { staticClass: "vx-col md:w-3/4 w-full" },
          [
            _c("GoogleStaticMap", {
              attrs: {
                mapLatLong: _vm.latLong,
                circleRadius: _vm.radiusmeter,
                location: _vm.place,
                multiMarkMap: _vm.mapdata,
              },
            }),
          ],
          1
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "vx-row" }, [
      _c("div", { staticClass: "vx-col w-full mb-6" }, [
        _c("span", { staticClass: "leading-none font-medium underline" }, [
          _c("strong", [_vm._v("Transfer Search Criteria")]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "vx-col" }, [
      _c("span", [_c("strong", [_vm._v(" Point 1 Name:")])]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "vx-col" }, [
      _c("span", [_c("strong", [_vm._v(" Point 1 Latitude / Longitude:")])]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "vx-col" }, [
      _c("span", [_c("strong", [_vm._v(" Point 2 Name:")])]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "vx-col" }, [
      _c("span", [_c("strong", [_vm._v(" Point 2 Latitude / Longitude:")])]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "vx-col" }, [
      _c("span", [_c("strong", [_vm._v(" Dates:")])]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "vx-row" }, [
      _c("div", { staticClass: "vx-col md:w-3/4 w-full mt-2" }, [
        _c("span", [_c("strong", [_vm._v(" Passengers:")])]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }