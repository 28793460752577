var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("vs-divider", { staticClass: "mb-5" }),
      _vm._m(0),
      _c("div", { staticClass: "vx-row" }, [
        _c("div", { staticClass: "vx-col w-full md:w-1/2" }, [
          _c("div", { staticClass: "vx-row" }, [
            _vm._m(1),
            _c("div", { staticClass: "vx-col" }, [
              _c("span", [
                _vm._v(
                  " " +
                    _vm._s(_vm.accomSearchData.locationName) +
                    " (" +
                    _vm._s(_vm.accomSearchData.radius) +
                    " " +
                    _vm._s(_vm.distUnit) +
                    ")"
                ),
              ]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "vx-col w-full md:w-1/2" }, [
          _c("div", { staticClass: "vx-row" }, [
            _vm._m(2),
            _c("div", { staticClass: "vx-col" }, [
              _c("span", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.getDateFormat(
                        _vm.accomSearchData.checkinDate,
                        _vm.accomSearchData.checkoutDate
                      )
                    )
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "vx-row" }, [
        _c("div", { staticClass: "vx-col w-full md:w-1/2" }, [
          _c("div", { staticClass: "vx-row" }, [
            _vm._m(3),
            _c("div", { staticClass: "vx-col" }, [
              _c("span", [
                _vm._v(" " + _vm._s(_vm.accomSearchData.boardBasis)),
              ]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "vx-col w-full md:w-1/2" }, [
          _c("div", { staticClass: "vx-row" }, [
            _vm._m(4),
            _c("div", { staticClass: "vx-col" }, [
              _c("span", [
                _vm._v(" " + _vm._s(_vm.accomSearchData.minStarRating)),
              ]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "vx-col w-full md:w-1/2" }, [
          _c("div", { staticClass: "vx-row" }, [
            _vm._m(5),
            _c("div", { staticClass: "vx-col" }, [
              _c("span", [
                _vm._v(" " + _vm._s(_vm.accomSearchData.refundableOnly)),
              ]),
            ]),
          ]),
        ]),
      ]),
      _vm._m(6),
      _c(
        "div",
        { staticClass: "vx-row" },
        [
          _vm._l(_vm.accomSearchData.rooms, function (room, roomIndex) {
            return [
              _c(
                "div",
                { key: roomIndex + "0", staticClass: "vx-col w-full" },
                [
                  _c("span", [
                    _c("strong", [
                      _vm._v("Room " + _vm._s(roomIndex + 1) + ":"),
                    ]),
                    _vm._v("  " + _vm._s(room.typePreference) + " Room Type"),
                  ]),
                ]
              ),
              _vm._l(room.guests, function (guest, guestIndex) {
                return [
                  _c(
                    "div",
                    {
                      key: guestIndex + "02",
                      staticClass: "vx-col",
                      class:
                        guest.type.toLowerCase() === "child" ? "" : "w-full",
                    },
                    [_c("span", [_vm._v(" " + _vm._s(guest.type))])]
                  ),
                  guest.type.toLowerCase() === "child"
                    ? _c(
                        "div",
                        { key: guestIndex + "1", staticClass: "vx-col" },
                        [_c("span", [_vm._v("Age: " + _vm._s(guest.age))])]
                      )
                    : _vm._e(),
                ]
              }),
            ]
          }),
        ],
        2
      ),
      _c("div", { staticClass: "vx-row" }, [
        _c(
          "div",
          { staticClass: "vx-col md:w-3/4 w-full" },
          [_c("GoogleStaticMap", { attrs: { multiMarkMap: _vm.mapdata } })],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "vx-row" }, [
      _c("div", { staticClass: "vx-col w-full mb-6" }, [
        _c("span", { staticClass: "leading-none font-medium underline" }, [
          _c("strong", [_vm._v("Accommodation Search Criteria")]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "vx-col" }, [
      _c("span", [_c("strong", [_vm._v(" Location:")])]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "vx-col" }, [
      _c("span", [_c("strong", [_vm._v(" Dates:")])]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "vx-col" }, [
      _c("span", [_c("strong", [_vm._v(" Board Basis:")])]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "vx-col" }, [
      _c("span", [_c("strong", [_vm._v(" Minimum Star Rating:")])]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "vx-col" }, [
      _c("span", [_c("strong", [_vm._v(" Refundable Only:")])]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "vx-row" }, [
      _c("div", { staticClass: "vx-col md:w-3/4 w-full mt-2" }, [
        _c("span", [_c("strong", [_vm._v(" Rooms:")])]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }