var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("vs-divider", { staticClass: "mb-5" }),
      _vm._m(0),
      _c("div", { staticClass: "vx-row" }, [
        _c("div", { staticClass: "vx-col w-full md:w-1/2" }, [
          _c("div", { staticClass: "vx-row" }, [
            _vm._m(1),
            _c("div", { staticClass: "vx-col" }, [
              _c("span", [
                _vm._v(" " + _vm._s(_vm.airportData.extraType) + " "),
              ]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "vx-col w-full md:w-1/2" }, [
          _c("div", { staticClass: "vx-row" }, [
            _vm._m(2),
            _c("div", { staticClass: "vx-col md:w-2/4" }, [
              _c("span", [_vm._v(" " + _vm._s(_vm.airportData.originName))]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "vx-col w-full md:w-1/2" }, [
          _c("div", { staticClass: "vx-row" }, [
            _vm._m(3),
            _c("div", { staticClass: "vx-col md:w-2/4" }, [
              _c("span", [
                _vm._v(" " + _vm._s(_vm.airportData.destinationName) + " "),
              ]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "vx-col w-full md:w-1/2" }, [
          _c("div", { staticClass: "vx-row" }, [
            _vm._m(4),
            _c("div", { staticClass: "vx-col" }, [
              _c("span", [
                _vm._v(" " + _vm._s(_vm.airportData.returnFlightNo)),
              ]),
            ]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "vx-row" }, [
        _c("div", { staticClass: "vx-col w-full md:w-1/2" }, [
          _c("div", { staticClass: "vx-row" }, [
            _vm._m(5),
            _c("div", { staticClass: "vx-col md:w-2/4" }, [
              _c("span", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.getDateFormat(
                        _vm.airportData.returnFlightDepartDateTime
                      )
                    )
                ),
              ]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "vx-col w-full md:w-1/2" }, [
          _c("div", { staticClass: "vx-row" }, [
            _vm._m(6),
            _c("div", { staticClass: "vx-col md:w-2/4" }, [
              _c("span", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.getDateFormat(
                        _vm.airportData.returnFlightArrivalDateTime
                      )
                    )
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
      _vm._m(7),
      _vm._l(_vm.airportData.passengers, function (pass, passengerIndex) {
        return [
          _c("div", { key: passengerIndex + "01", staticClass: "vx-row" }, [
            _c(
              "div",
              {
                key: passengerIndex + "0",
                staticClass: "vx-col",
                class: pass.type.toLowerCase() === "child" ? "" : "w-full",
              },
              [_c("span", [_vm._v(" " + _vm._s(pass.type))])]
            ),
            pass.type.toLowerCase() === "child"
              ? _c(
                  "div",
                  { key: passengerIndex + "1", staticClass: "vx-col" },
                  [_c("span", [_vm._v("Age: " + _vm._s(pass.age))])]
                )
              : _vm._e(),
          ]),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "vx-row" }, [
      _c("div", { staticClass: "vx-col w-full mb-6" }, [
        _c("span", { staticClass: "leading-none font-medium underline" }, [
          _c("strong", [_vm._v("Airport Extras Search Criteria")]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "vx-col" }, [
      _c("span", [_c("strong", [_vm._v(" Extra Type:")])]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "vx-col md:w-2/4" }, [
      _c("span", [_c("strong", [_vm._v(" Origin Name:")])]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "vx-col md:w-2/4" }, [
      _c("span", [_c("strong", [_vm._v(" Destination Name:")])]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "vx-col" }, [
      _c("span", [_c("strong", [_vm._v(" Return Flight No:")])]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "vx-col md:w-2/4" }, [
      _c("span", [_c("strong", [_vm._v(" Return Flight Depart DateTime:")])]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "vx-col md:w-2/4" }, [
      _c("span", [_c("strong", [_vm._v(" Return Flight Arrival DateTime:")])]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "vx-row" }, [
      _c("div", { staticClass: "vx-col md:w-3/4 w-full mt-2" }, [
        _c("span", [_c("strong", [_vm._v(" Passengers:")])]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }